input[type=checkbox] {
    width: 1em;
    height: 1em;
}

/* For Firefox only */
@-moz-document url-prefix() {
    input[type=file] {
        height: auto;
    }
}


