html, body {
  height: 100%;
}

body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;

    color: #555;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/*
 * Override Bootstrap's default container.
 */

header {
    //font-family: "Times New Roman", "Times-Roman", "Times", serif;
    //font-style: italic;
    padding: 0.25em;
    background: $brand_primary;
}

header a {
    color: #fff;
    color: #000;
}

header a:hover {
    color: #000;
    text-decoration: none;
}

a.positive:link {
    background-color: #ccffcc;
}

a.negative:link {
    background-color: #ffcccd;
}

a.calendar-event {
    color: #ddddff;
}

/*
    Reducing the 1em causes problems: click on email address on page that does not
    have a scrollbar and one appears.
*/
nav {
    padding: 0 1em;
}

.nav li a {
    padding: 8px 12px;
}

.navbar {
    margin-bottom: 0px;
    background-color: $brand_secondary;
    min-height: 0px;
}

.container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.positive {
    background-color: #ccffcc;
}

.negative {
    background-color: #ffcccd;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 5px;
    margin-bottom: 5px;
}

h2 {
    font-size: 25px;
}

h3 {
    font-size: 20px;
}

h3.panel-title {
    font-weight: bold;
}

h4 {
    font-size: 18px;
}

ul.nav.navbar-nav {
    margin-top: 0;
}

.navbar-default .navbar-nav li a {
    color: #333;
}

.navbar-nav li a {
    padding-top: 0;
    padding-bottom: 0;
}

btn.approve {
    background-color: #cfc;
}

span.impersonator {
    background-color: darken($brand-secondary, 10%);
    border-color: darken($brand-secondary, 20%);;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
}

span.positive {
    background-color: #ccffcc;
}

span.negative {
    background-color: #ffcccd;
}

span.yellow {
    background-color: #ffff00;
}

span.info {
    background-color: #cceeff;
}

span.gray {
    background-color: #dddddd;
}

span.white {
    background-color: #ffffff;
}

span.app-result-1 {
    background-color: #ffffff;
}

span.app-result-2 {
    background-color: #ffcccd;
}

span.app-result-4 {
    background-color: #cceeff
}

span.app-result-8 {
    background-color: #ccffcc;
}

span.app-result-16 {
    background-color: #ffff99;
}

span.app-result-32 {
    background-color: #ffdd99;
}

span.app-result-64 {
    background-color:#dddddd;
}

span.highlight {
    padding: 2px 4px;
    font-size: 90%;
    color:#333cd1;
    background-color:#cfe7ff;
    border-radius: 4px;
}

span.reviewer {
    background-color:#1b7736;
    color: #ffffff;
}

span.manager {
    background-color:#3557ff;
    color: #ffffff;
}

span.common {
    background-color:#9d6cd8;
    color: #ffffff;
}

span.individual {
    background-color:#d76478;
    color: #ffffff;
}

span.disk {
    display: inline-block;
    text-indent: 0em;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    width: 1.2em;
}

span.square {
    display: inline-block;
    text-indent: 0em;
    text-align: center;
    border-radius: 0%;
    color: #fff;
    width: 1.2em;
}

span.triangle {
    display: inline-block;
    text-indent: -0.3em;
    border-radius: 0%;
    color: #fff;
    width: 0em;
    height: 0em;
    border-left: 0.6em solid transparent;
    border-right: 0.6em solid transparent;
}

span.app-star {
    text-indent: 0em;
    font-size: 1.3em;
}

span.app-asterisk {
    text-indent: 0em;
    font-size: 1.3em;
}

span.app-hand-right {
    text-indent: 0em;
    font-size: 1.3em;
}

.brand-tertiary {
    background-color: lighten($brand_secondary, 10%);
}

.brand-quad {
    background-color: lighten($brand_secondary, 15%);
}

.annoucement-a {
    background-color: lighten($brand_secondary, 15%);
}

.annoucement-b {
    background-color: #ffedef;
}

.label-slight {
    background-color: #ffee33;
    color: #aaaaaa;
}

.label-minor {
    background-color: #ff8822;
    color: #ffffff;
}

.label-major {
    background-color: #ff3311;
    color: #ffffff;
}

.label-pending {
    background-color: #ffee33;
    color: #aaaaaa;
}

.label-todo {
    background-color: #003377;
    color: #ffffff;
}

.label-completed {
    background-color: #227700;
    color: #ffffff;
}

.label-Admin {
    background-color: #ffcc00;
    color: #ffffff;
}

.label-Applicant {
    background-color: #1c00bc;
    color: #ffffff;
}

.label-Recommender {
    background-color: #006830;
    color: #ffffff;
}

.label-Proxy {
    background-color: #006064;
    color: #ffffff;
}

.label-Recruiter {
    background-color: #cc3300;
    color: #ffffff;
}

.label-notplanned {
    background-color: #777777;
    color: #ffffff;
}

.relation-all {
    background-color: #ccffcc;
}

.relation-none {
    background-color: #ffffff;
}

.relation-letters {
    background-color: #cceeff;
}

.grayed {
    background-color: #dddddd;
    color: #777777;
}

.tier-1 {
    background-color:#666666;
    color: #fff;
}

.tier-2 {
    background-color:#0078f4;
    color: #fff;
}

.tier-3 {
    background-color:#ffb645;
    color: #fff;
}

ul.dropdown-menu li {
    padding: 3px 20px;
}

.dropdown-menu li a {
    padding: 0;
}

ul.plain {
  list-style-type: none;
  margin-bottom: 1em;
  margin-top: 0em;
  margin-left: 0em;
}

ul.plainHang {
  list-style-type: none;
  margin-bottom: 1em;
  margin-top: 0em;
  margin-left: 0em;
  padding-left: 1em;
  text-indent: -1em;
}

footer {
    background-color: #f9f9f9;
    border-top: 0.05rem solid #e5e5e5;
    font-size: 0.9em;
    padding: 1.5rem 0;
    background: rgba(0, 0, 0, 0.8);
    color: #999;
    font-size: 12px;
    padding: 0;
    margin-top: auto;
}


footer nav {
    display: flex;
    justify-content: space-around;
}

footer h2 {
    font-size: 1.4em;
}

.footerColumn {
    padding: 0 0.5em 0 0.5em;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer li a {
    color: darken($brand_secondary, 10%);
}

footer li a:hover {
    color: darken($brand_secondary, 20%);
    text-decoration: none;
}

footer .copy {
    padding: 1em 0 2em;
    text-align: center;
}

.help {
    color: #3498db;
    font-weight: bold;
    padding: 0 8px 5px;
    display: inline-box;
    vertical-align: middle;
    border: none;
    background: transparent;
}

hr.type-field-separator {
    display: block;
    position: relative;
    padding: 0;
    margin: 0px auto;
    height: 0;
    width: 100%;
    max-height: 0;
    font-size: 0px;
    line-height: 0;
    clear: both;
    border: none;
    margin-top: 0px;
    margin-bottom: 0px;
}

@media (min-width: 992px) {
    hr.type-field-separator {
        margin: 8px auto;
        border-bottom: 1px solid #cccccc;
        font-size: 1px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

@media (min-width: 768px) {
    .navbar-nav li a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

// To maximize screen usage on a small device
@media (max-width: 576px) {
    .col-md-10 {
        padding-left: 0px;
        padding-right: 0px;
    }
}

/* If user has selected dark mode ...
 * Need to make more extensive changes
*/
/*
.day   { background: #eee; color: black; }
.night { background: #333; color: white; }

@media (prefers-color-scheme: dark) {
  .day.dark-scheme   { background:  #333; color: white; }
  .night.dark-scheme { background: black; color:  #ddd; }
  body { background-color: black;}
  .panel-heading {background-color: black;}
  .panel-body {background-color: black;}
}

@media (prefers-color-scheme: light) {
  .day.light-scheme   { background: white; color:  #555; }
  .night.light-scheme { background:  #eee; color: black; }
}
*/
